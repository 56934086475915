let allCritters = [
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Agrias Butterfly",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 3000
  },
  {
    "Location": "On rotten turnip",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Ant",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 80
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Atlas Moth",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 4 AM",
    "Value": 3000
  },
  {
    "Location": "Shake trees",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Bagworm",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 600
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Banded Dragonfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 4500
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Bell Cricket",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 430
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Blue Weevil Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 800
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Brown Cicada",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 200
  },
  {
    "Location": "Hit a rock",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Centipede",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": false,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": false
    },
    "TimeString": "4 PM - 11 PM",
    "Value": 300
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Cicada Shell",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 100
  },
  {
    "Location": "Tree Stumps",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Citrus Long-horned Beetle",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 350
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Common Bluebottle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4am - 7pm",
    "Value": 300
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Common Butterfly",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 7 PM",
    "Value": 160
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Cricket",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 130
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Cyclommatus Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 8000
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Damselfly",
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 500
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Darner Dragonfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 230
  },
  {
    "Location": "On Ponds and Rivers",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Diving Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 800
  },
  {
    "Location": "On Tree",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Drone Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 80
  },
  {
    "Location": "Rolling Snowballs",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Dung Beetle",
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM to 8 AM",
    "Value": 800
  },
  {
    "Location": "On Ground",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Earth-boring Dung Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 800
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Emperor Butterfly",
    "Southern": {
      "April": false,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 4000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Evening Cicada",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 8 AM & 4 PM - 7 PM",
    "Value": 550
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Firefly",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 4 AM",
    "Value": 300
  },
  {
    "Location": "Villager's Heads",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Flea",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 70
  },
  {
    "Location": "On boot",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Fly",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 80
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Giant Cicada",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 500
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Giant Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": true
    },
    "TimeString": "11 PM - 8 AM",
    "Value": 10000
  },
  {
    "Location": "On Ponds and Rivers",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Giant water bug",
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 8 AM",
    "Value": 2000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Giraffe Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 12000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Golden Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 12000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Goliath Beetle",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 6000
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Grasshopper",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 160
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Great Purple Emperor",
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 7 PM",
    "Value": 3000
  },
  {
    "Location": "Beach",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Hermit Crab",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 8 AM",
    "Value": 1000
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Honeybee",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": false,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 200
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Horned Atlas",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 8000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Horned Dynastid",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 1350
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Horned Elephant",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 8000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Horned Hercules",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 8 AM",
    "Value": 12000
  },
  {
    "Location": "Tree Stumps",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Jewel Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 2400
  },
  {
    "Location": "On flowers",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": false
    },
    "Name": "Ladybug",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 200
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Long Locust",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 200
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Madagascan Sunset Moth",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 4 PM",
    "Value": 2500
  },
  {
    "Location": "On flowers",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Man-faced Stink Bug",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 8 AM",
    "Value": 1000
  },
  {
    "Location": "On flowers",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Mantis",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": false,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 430
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Migratory Locust",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 600
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Miyama Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 1000
  },
  {
    "Location": "Underground",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Mole Cricket",
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 500
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Monarch Butterfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 5 PM",
    "Value": 140
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": true,
      "December": true,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Mosquito",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "5 PM - 4 AM",
    "Value": 130
  },
  {
    "Location": "Flying by light",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Moth",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 4 AM",
    "Value": 130
  },
  {
    "Location": "On flowers",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Orchid Mantis",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 2400
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Paper Kite Butterfly",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 1000
  },
  {
    "Location": "Flying near purple, black, and blue flowers",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Peacock Butterfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 7 PM",
    "Value": 2500
  },
  {
    "Location": "Hit a rock",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Pill bug",
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": true
    },
    "TimeString": "11 PM - 4 PM",
    "Value": 250
  },
  {
    "Location": "On Ponds and Rivers",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Pondskater",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 130
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Queen Alexandra's Birdwing",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 4 PM",
    "Value": 3000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Rainbow Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 8 AM",
    "Value": 10000
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Rajah Brooke's Birdwing",
    "Southern": {
      "April": false,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": false,
      "November": false,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 2500
  },
  {
    "Location": "Flying",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Red Dragonfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 180
  },
  {
    "Location": "Hopping",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Rice Grasshopper",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 7 PM",
    "Value": 160
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Robust Cicada",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 300
  },
  {
    "Location": "Tree Stumps",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Rosalia Batesi Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 3000
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Saw Stag",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 2000
  },
  {
    "Location": "On Tree",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Scarab Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": true
    },
    "TimeString": "11 PM - 8 AM",
    "Value": 6000
  },
  {
    "Location": "On the ground",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Scorpion",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 4 AM",
    "Value": 8000
  },
  {
    "Location": "On rocks when raining",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Snail",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 250
  },
  {
    "Location": "Shake tree",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Spider",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 8 AM",
    "Value": 300
  },
  {
    "Location": "On flowers",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Stinkbug",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 120
  },
  {
    "Location": "On the ground",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Tarantula",
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "7 PM - 4 AM",
    "Value": 8000
  },
  {
    "Location": "On the ground",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": true,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Tiger Beetle",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 1500
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Tiger Butterfly",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 7 PM",
    "Value": 240
  },
  {
    "Location": "Tree Stumps",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Violin Beetle",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 260
  },
  {
    "Location": "On Trees",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Walker Cicada",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "8 AM - 5 PM",
    "Value": 400
  },
  {
    "Location": "Under Trees Disguised as Leafs",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Walking Leaf",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 600
  },
  {
    "Location": "Shaking Tree",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Walking Stick",
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 8 AM & 4 PM - 6 PM",
    "Value": 600
  },
  {
    "Location": "Shake tree",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Wasp",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 2500
  },
  {
    "Location": "On beach rocks",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Wharf Roach",
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All day",
    "Value": 200
  },
  {
    "Location": "Flying",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Yellow Butterfly",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 7 PM",
    "Value": 160
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Anchovy",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 9 PM",
    "Value": 200
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Angelfish",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 3000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Arapaima",
    "Shadow": 6,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 10000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Arowana",
    "Shadow": 4,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 10000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Barred Knifejaw",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 5000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Barreleye",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "9 PM - 4 AM",
    "Value": 15000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Betta",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 2500
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Bitterling",
    "Shadow": 1,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 900
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Black Bass",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 400
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Blowfish",
    "Shadow": 3,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "6 PM - 4 AM",
    "Value": 5000
  },
  {
    "Location": "Pier",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": true
    },
    "Name": "Blue Marlin",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 10000
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Bluegill",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 180
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Butterfly fish",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1000
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Carp",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 300
  },
  {
    "Location": "Pond",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Catfish",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 800
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Char",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 3800
  },
  {
    "Location": "River (Clifftop)",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Cherry Salmon",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 1000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Clownfish",
    "Shadow": 1,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 650
  },
  {
    "Location": "Ocean (Rain)",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Coelacanth",
    "Shadow": 6,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 15000
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Crawfish",
    "Shadow": 3,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 200
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Crucian Carp",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 160
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Name": "Dab",
    "Shadow": 3,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 300
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Dace",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 240
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Dorado",
    "Shadow": 5,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 9 PM",
    "Value": 15000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Football Fish",
    "Shadow": 4,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 2500
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Freshwater Goby",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 400
  },
  {
    "Location": "Pond",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Frog",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 120
  },
  {
    "Location": "Pond",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Gar",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 6000
  },
  {
    "Location": "Pond",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Giant snakehead",
    "Shadow": 5,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 6500
  },
  {
    "Location": "Pier",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Giant Trevally",
    "Shadow": 5,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 4500
  },
  {
    "Location": "River (Clifftop)",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Golden Trout",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 15000
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Goldfish",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1300
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Great White Shark",
    "Shadow": "6 (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 15000
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Guppy",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 1300
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Hammerhead Shark",
    "Shadow": "6 (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 8000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Horse Mackerel",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 150
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Killifish",
    "Shadow": 1,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 300
  },
  {
    "Location": "River (Mouth)",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "King Salmon",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1800
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Koi",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "2 PM - 9 AM",
    "Value": 4000
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Loach",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 400
  },
  {
    "Location": "Pier",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Mahi-mahi",
    "Shadow": 5,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 6000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Mitten Crab",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 2000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Moray Eel",
    "Shadow": 5,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 2000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Napoleon Fish",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 9 PM",
    "Value": 10000
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Neon Tetra",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 500
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Nibble Fish",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 1500
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Oarfish",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 9000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Ocean Sunfish",
    "Shadow": "Unknown (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 9 PM",
    "Value": 4000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Olive Flounder",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 800
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Pale Chub",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 200
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Pike",
    "Shadow": 5,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1800
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Piranha",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "9 AM - 4 PM & 9 PM - 4 AM",
    "Value": 2500
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Pond Smelt",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 320
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Pop-eyed Goldfish",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 1300
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Puffer Fish",
    "Shadow": 3,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 250
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Rainbowfish",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 800
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Ranchu Goldfish",
    "Shadow": 2,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "9 AM - 4 PM",
    "Value": 4500
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Ray",
    "Shadow": 5,
    "Southern": {
      "April": true,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": false,
      "1": false,
      "2": false,
      "3": false,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": false,
      "22": false,
      "23": false
    },
    "TimeString": "4 AM - 9 PM",
    "Value": 3000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Red Snapper",
    "Shadow": 4,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 3000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Ribbon Eel",
    "Shadow": "Narrow",
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 600
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Saddled Bichir",
    "Shadow": 4,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "9 PM - 4 AM",
    "Value": 4000
  },
  {
    "Location": "River (Mouth)",
    "Months": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Salmon",
    "Shadow": 4,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 700
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Saw Shark",
    "Shadow": "6 (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 12000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Sea Bass",
    "Shadow": 5,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 400
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Sea Butterfly",
    "Shadow": 1,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Seahorse",
    "Shadow": 1,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1100
  },
  {
    "Location": "River",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Snapping Turtle",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": false,
      "5": false,
      "6": false,
      "7": false,
      "8": false,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": false,
      "19": false,
      "20": false,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "9 PM - 4 AM",
    "Value": 5000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Soft-shelled Turtle",
    "Shadow": "Unknown",
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": false,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 3750
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Squid",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": true,
      "December": true,
      "February": true,
      "January": true,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 500
  },
  {
    "Location": "River (Clifftop)",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Stringfish",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": false,
      "10": false,
      "11": false,
      "12": false,
      "13": false,
      "14": false,
      "15": false,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "4 PM - 9 AM",
    "Value": 15000
  },
  {
    "Location": "River (Mouth)",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Sturgeon",
    "Shadow": 6,
    "Southern": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 10000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Suckerfish",
    "Shadow": "Unknown (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1500
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Surgeonfish",
    "Shadow": 2,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 1000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Sweetfish",
    "Shadow": 3,
    "Southern": {
      "April": false,
      "August": false,
      "December": false,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 900
  },
  {
    "Location": "Pond",
    "Months": {
      "April": true,
      "August": false,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": true,
      "May": true,
      "November": false,
      "October": false,
      "September": false
    },
    "Name": "Tadpole",
    "Shadow": 1,
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": false,
      "January": true,
      "July": false,
      "June": false,
      "March": false,
      "May": false,
      "November": true,
      "October": true,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 100
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": true,
      "September": true
    },
    "Name": "Tilapia",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 800
  },
  {
    "Location": "Pier",
    "Months": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": false,
      "September": false
    },
    "Name": "Tuna",
    "Shadow": 6,
    "Southern": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 7000
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": false,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": false,
      "November": false,
      "October": false,
      "September": true
    },
    "Name": "Whale Shark",
    "Shadow": "6 (Fin)",
    "Southern": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": false,
      "October": false,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 13000
  },
  {
    "Location": "River",
    "Months": {
      "April": false,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": false,
      "November": true,
      "October": true,
      "September": false
    },
    "Name": "Yellow Perch",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": true,
      "March": false,
      "May": true,
      "November": false,
      "October": false,
      "September": true
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 300
  },
  {
    "Location": "Ocean",
    "Months": {
      "April": true,
      "August": true,
      "December": false,
      "February": false,
      "January": false,
      "July": true,
      "June": false,
      "March": false,
      "May": true,
      "November": true,
      "October": true,
      "September": true
    },
    "Name": "Zebra Turkeyfish",
    "Shadow": 3,
    "Southern": {
      "April": true,
      "August": false,
      "December": true,
      "February": true,
      "January": true,
      "July": false,
      "June": false,
      "March": true,
      "May": true,
      "November": true,
      "October": true,
      "September": false
    },
    "Time": {
      "0": true,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
      "8": true,
      "9": true,
      "10": true,
      "11": true,
      "12": true,
      "13": true,
      "14": true,
      "15": true,
      "16": true,
      "17": true,
      "18": true,
      "19": true,
      "20": true,
      "21": true,
      "22": true,
      "23": true
    },
    "TimeString": "All Day",
    "Value": 500
  }
]
export default allCritters;
