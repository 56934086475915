import React from 'react';

function Loadingsvg(props) {
    return (
        <div id={props.show ? "" : "loading-container"}>
        <div id="loading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 260">
                <g id="planet">
          <circle className="blue" cx="108.5" cy="148.67" r="108.5"/>
        <path className="green" d="M143.87,58.9a10.61,10.61,0,0,0-2.7,5.62c-.45,5.38-1.19,10,.3,15.2,2.18,7.58,16.76,8.87,15.62,16.67-.73,5-5.64,11.37-5,16.38.74,5.78,1.76,8.47,6,11.15,3.93,2.46,10.86,8.62,14.78,11.08,7.18,4.5,10.2-9,18.22-11.66s17.16,7.07,20.1-1c.45-1.24.92-2.75,1.35-4.38a108.77,108.77,0,0,0-58.11-67.58A32,32,0,0,0,143.87,58.9Z"/>
        <path className="green" d="M29.59,106.79l.49,3.31c.87,5,2.08,5,4.3,9.69.92,2,2.19,4.08,4.26,4.77a21,21,0,0,0,3.17.45c2.58.43,12.57,4.21,14.34,6.24,7,8-2.88,18.67,3.53,27.14a12.33,12.33,0,0,0,3.47,3.36,14.72,14.72,0,0,0,4.11,1.41c2.45.57,9.14-.56,11.59,0,1.94.46,4,.91,5.79.28s3-2.41,3.93-4.11c2.14-4,3.72-8.6,2.45-13.25-.73-2.67-2.35-5.11-3.32-7.74-1.27-3.44-1.53-7.36-4.14-10.09-1-1-2.27-1.81-3.24-2.88-2.61-2.86-2.42-6.93-2.45-10.55a56.06,56.06,0,0,0-6.51-25.47,14.67,14.67,0,0,0-3.58-4.82C63.93,81.47,58.13,82.26,54.4,79a13.05,13.05,0,0,1-3.21-4.79A3.41,3.41,0,0,0,50.4,73a3.29,3.29,0,0,0-1.31-.58l-4-1.07c-3-.8-6.33-2-7.87-4.48a107.31,107.31,0,0,0-10.9,11C26.13,87.45,26.79,97.24,29.59,106.79Z"/>
        <path className="green" d="M35,217.17c6.41-3.24,9.76-6,6.85-12.91a9.23,9.23,0,0,1-.91-2.76c-.16-2,1.45-3.88,3.33-4.69a15.91,15.91,0,0,1,6-.82c5.7-.06.06-2.14,4.71-5.29,1.92-1.3,8.3-5.44,5.89-8.51a7.8,7.8,0,0,0-3.53-2.14c-2.82-1.09-6.28-3.18-9.29-3l-10.54-.36c-2.54.25-7.18-.71-6.36-4.27a10.54,10.54,0,0,1,2.05-3.54c1.63-2.33,8.38-1.13,10.33-3.13,1.74-1.8,4-2.92,5.11-5.31,1.82-4-1.92-4.81-5-4-4.37,1.07-16.53,2.87-20.51,4.89a42.22,42.22,0,0,1-10.58,4c-3,.6-6.61-.62-9.33.49a3.86,3.86,0,0,0-1,.6,4.57,4.57,0,0,0-.63.69A108.06,108.06,0,0,0,25,217.93C28.42,218.25,33.57,217.89,35,217.17Z"/>
        <path className="green" d="M114.87,257a108,108,0,0,0,41-10.66c-2.23-2.93-5.09-5.43-6.7-8.75a31.66,31.66,0,0,0-2.09-4.4,13.88,13.88,0,0,0-4.63-3.74q-4.59-2.67-9.4-5a6.94,6.94,0,0,0-3.93-1c-2.91.47-3.94,4-5.5,6.49-3.66,5.84-11.93,7-16.37,12.31a7.93,7.93,0,0,1-2.27,2.24,6.1,6.1,0,0,1-2.21.46c-3.83.31-7.76.61-11.46-.45-2.06-.59-4-1.59-6-2.21a34.7,34.7,0,0,0-7.32-1.09l-9-.67a13.73,13.73,0,0,0-4.1.09,28.44,28.44,0,0,0-2.95,1,14.4,14.4,0,0,1-9.35-.05,108,108,0,0,0,42.57,14.72c3.06-2.33,6.17-5.12,8.91-5.18C107.9,251.08,111.32,254.52,114.87,257Z"/>
        <path className="green" d="M175.33,195.46a6.58,6.58,0,0,0-3-.4c-1,.19-4.51,1.62-4.8,2.61-.54,1.82,1.62,6.61,1,8-2.89,6.45-3.54,4.2-3.48,11.27a15.11,15.11,0,0,0,1.71,7.46c1.65,2.86,4.56,4.73,7.43,6.38a6,6,0,0,0,4.58.58,108.89,108.89,0,0,0,18.86-20.77c-2.63-1.43-5.78-2.76-7.1-3.92C184.58,201.47,182.86,197.77,175.33,195.46Z"/>
        <path className="green" d="M212.91,139.24c-7.31-2.89-6.23,15.42-3.91,22.93,1.93,6.25-10.42,25.62-2,32a108.12,108.12,0,0,0,10-45.47c0-.48,0-1,0-1.45C216.25,143,215,140.05,212.91,139.24Z"/>
        <path className="green" d="M80.27,48.54c.76,2.38,3,3.72,2.34,6.49-.75,3.27-2.93,5.26-2.61,9.08a6.2,6.2,0,0,0,1,3.12c4.77,6.5,10.78-1.36,14.41-3.75,2.8-1.84,7.69,8,13.42,3.26a7.89,7.89,0,0,0,.78-11.08c-.44-.51-1-1.11-.79-1.75a1.84,1.84,0,0,1,.48-.66c3-3,9.52-6.8,7.61-11.87a6.46,6.46,0,0,0-.44-.92q-3.94-.28-8-.29A108.35,108.35,0,0,0,79.79,44,12.89,12.89,0,0,0,80.27,48.54Z"/>
        <path className="green" d="M85.73,217.68a6.16,6.16,0,0,0,.13-2.67c-.26-2.85-.35-5.79-.81-8.61-.31-1.92-1.35-4.32-3.24-5.17a3.26,3.26,0,0,0-.69-.22c-1.41-.29-2.87.61-4,1.29a22.29,22.29,0,0,0-4.52,3.57,20.55,20.55,0,0,0-4.93,9,6.06,6.06,0,0,0-.1,4.07,8.62,8.62,0,0,0,2,2.35c3,3,1.66,7.32,1.48,11.57a2.15,2.15,0,0,0,.44,1.8c.27.23.7.55,1,.34,2.6-1.84,7.6-1.84,12.23-.66.33.08.66-.21.89-.45.67-.71,0-1.84-.55-2.6a15.16,15.16,0,0,1-3-5.1,6.25,6.25,0,0,1,.47-4.85C83.32,219.9,85.13,219.36,85.73,217.68Z"/>
        <path className="green" d="M158.47,174.07c-1.22-6-4.59-12.06-4.4-18,.09-2.63.92-5,.44-7.75-.54-3-2.53-5.82-4.41-8.35-4.65-6.25-10.82-12.49-13.36-19.72a8.74,8.74,0,0,0-4.64-5.51c-2.37-1.08-5-1-7.22-1.22-3.4-.36-6.93-2.23-10.3-2.49a12.38,12.38,0,0,0-5.62,1.12c-6.11,2.46-16.61,11.5-9.81,23.27,2.93,5.09,7,9.55,9.36,15,2,4.79,1.14,8.94,1.93,13.59s3.43,9.89,5.53,14.43a12.51,12.51,0,0,0,2.52,4,7.32,7.32,0,0,0,3,1.5c5,1.5,10,1.83,15,4,3,1.32,5.51,3.77,7.79,6.29a22.79,22.79,0,0,0,4.92,4.56c1.91,1.12,4.36,1.48,6.06.26a7.87,7.87,0,0,0,1.81-2.08l3.78-5.53a6.31,6.31,0,0,0,1.2-2.45c.42-2.35-1.55-4.29-2.32-6.53C158.67,179.61,159.08,177.06,158.47,174.07Z"/>
                </g>
                <g id="critter">
                    <path className="brown" d="M88.12,30.14a9.68,9.68,0,0,0,.77,2.68,10,10,0,0,0,2.54,3.44c.3.26,3.14,1.94,2.85,2.17-.44.35-.78.68-.84.73-3.15,2.67-5.31,4.86-2.33,8.25,1.92,2.19,4.29,1.22,6.46,0a38.1,38.1,0,0,1,18.4-4.7,34.06,34.06,0,0,1,9.41,1.38c3.32,1,8,4.57,11.46,2.33,5.52-3.56-1.55-7.35-3.78-9.81.81.89,4.09-1.13,4.73-1.58a23.52,23.52,0,0,0,4.53-4.27c2.21-2.59,5.13-6.3,5.27-9.88l-7.21-1.32c.18-4.81-3-10.27-7.1-11.55a35.22,35.22,0,0,0-4.59-1,4.23,4.23,0,0,0,.41-1.51l.12-1.45A3.65,3.65,0,0,0,125.88.13L124.43,0a3.66,3.66,0,0,0-3.93,3.34l-.12,1.45a4.7,4.7,0,0,0,.08,1.25c-5.56-.42-11.36-.39-14.84.47-7.1,1.73-16.15,4-17.35,12.5a11.22,11.22,0,0,0-.11,1.29A1.49,1.49,0,0,1,88,21c-.47.77-2.79-.23-3.37-.53A5.69,5.69,0,0,1,82,17.75c-.94-2.12-2.37-6.78-6.73-7.13-5.57-.46-6.78,6.73-5.31,11.26,2.13,6.61,9.37,7.35,15.32,6.51.58-.08,1.1-.55,1.68-.59,1-.05,1,1.37,1.09,2C88.1,29.92,88.11,30,88.12,30.14Z"/>
                    <path className="tan" d="M75.36,14.64c.65-.49,3.64-.32,4.77-.84a6.29,6.29,0,0,0-4.83-3.18c-5.13-.42-6.55,5.62-5.61,10.12C72.47,20.91,73,16.38,75.36,14.64Z"/>
                    <path className="tan" d="M79.41,24a21.25,21.25,0,0,0,3.5-2.83c.39-.36.83-.69,1.15-1a5.71,5.71,0,0,1-1.61-1.6,3.86,3.86,0,0,1-.76.9,24.77,24.77,0,0,1-3.21,2.4c-.72.48-2.42,1.41-3.69,2.07a8.52,8.52,0,0,1-1.84.7,9.44,9.44,0,0,0-1.29.4,8.46,8.46,0,0,0,2,1.74,8.85,8.85,0,0,0,1.54-.54C76.3,25.64,78.43,24.6,79.41,24Z"/>
                    <path className="tan" d="M129.8,15.15c-1.88.89-2.4,2.84-2.27,4.79a3.27,3.27,0,0,0,1.33,2.56,6.76,6.76,0,0,0,6,.32c1.79-.94,2.67-3.22,4.53-4l1-.34a13.47,13.47,0,0,0-1.92-5.93A9.49,9.49,0,0,1,135.72,14C133.78,14.48,131.64,14.28,129.8,15.15Z"/>
                    <path className="tan" d="M126.72,3.38c-.41-.36-.7-.58-1.27-.42-.92.26-.84.68-.9,1.29s-.41,2,.12,2.16a2.05,2.05,0,0,0,.65.1c.81,0,1.83,0,2.12-1A2.16,2.16,0,0,0,126.72,3.38Z"/>
                    <circle className="brown" cx="132.53" cy="19.39" r="1.41"/>
                </g>
            </svg>
        </div>
        </div>
    );
}

export default Loadingsvg;
