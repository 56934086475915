let critters = {"agrias butterfly": [false,false],
"ant": [false,false],
"atlas moth": [false,false],
"bagworm": [false,false],
"banded dragonfly": [false,false],
"bell cricket": [false,false],
"blue weevil beetle": [false,false],
"brown cicada": [false,false],
"centipede": [false,false],
"cicada shell": [false,false],
"citrus long-horned beetle": [false,false],
"common bluebottle": [false,false],
"common butterfly": [false,false],
"cricket": [false,false],
"cyclommatus stag": [false,false],
"damselfly": [false,false],
"darner dragonfly": [false,false],
"diving beetle": [false,false],
"drone beetle": [false,false],
"dung beetle": [false,false],
"earth-boring dung beetle": [false,false],
"emperor butterfly": [false,false],
"evening cicada": [false,false],
"firefly": [false,false],
"flea": [false,false],
"fly": [false,false],
"giant cicada": [false,false],
"giant stag": [false,false],
"giant water bug": [false,false],
"giraffe stag": [false,false],
"golden stag": [false,false],
"goliath beetle": [false,false],
"grasshopper": [false,false],
"great purple emperor": [false,false],
"hermit crab": [false,false],
"honeybee": [false,false],
"horned atlas": [false,false],
"horned dynastid": [false,false],
"horned elephant": [false,false],
"horned hercules": [false,false],
"jewel beetle": [false,false],
"ladybug": [false,false],
"long locust": [false,false],
"madagascan sunset moth": [false,false],
"man-faced stink bug": [false,false],
"mantis": [false,false],
"migratory locust": [false,false],
"miyama stag": [false,false],
"mole cricket": [false,false],
"monarch butterfly": [false,false],
"mosquito": [false,false],
"moth": [false,false],
"orchid mantis": [false,false],
"paper kite butterfly": [false,false],
"peacock butterfly": [false,false],
"pill bug": [false,false],
"pondskater": [false,false],
"queen alexandras birdwing": [false,false],
"rainbow stag": [false,false],
"rajah brookes birdwing": [false,false],
"red dragonfly": [false,false],
"rice grasshopper": [false,false],
"robust cicada": [false,false],
"rosalia batesi beetle": [false,false],
"saw stag": [false,false],
"scarab beetle": [false,false],
"scorpion": [false,false],
"snail": [false,false],
"spider": [false,false],
"stinkbug": [false,false],
"tarantula": [false,false],
"tiger beetle": [false,false],
"tiger butterfly": [false,false],
"violin beetle": [false,false],
"walker cicada": [false,false],
"walking leaf": [false,false],
"walking stick": [false,false],
"wasp": [false,false],
"wharf roach": [false,false],
"yellow butterfly": [false,false],
"anchovy": [false,false],
"angelfish": [false,false],
"arapaima": [false,false],
"arowana": [false,false],
"barred knifejaw": [false,false],
"barreleye": [false,false],
"betta": [false,false],
"bitterling": [false,false],
"black bass": [false,false],
"blowfish": [false,false],
"blue marlin": [false,false],
"bluegill": [false,false],
"butterfly fish": [false,false],
"carp": [false,false],
"catfish": [false,false],
"char": [false,false],
"cherry salmon": [false,false],
"clownfish": [false,false],
"coelacanth": [false,false],
"crawfish": [false,false],
"crucian carp": [false,false],
"dab": [false,false],
"dace": [false,false],
"dorado": [false,false],
"football fish": [false,false],
"freshwater goby": [false,false],
"frog": [false,false],
"gar": [false,false],
"giant snakehead": [false,false],
"giant trevally": [false,false],
"golden trout": [false,false],
"goldfish": [false,false],
"great white shark": [false,false],
"guppy": [false,false],
"hammerhead shark": [false,false],
"horse mackerel": [false,false],
"killifish": [false,false],
"king salmon": [false,false],
"koi": [false,false],
"loach": [false,false],
"mahi-mahi": [false,false],
"mitten crab": [false,false],
"moray eel": [false,false],
"napoleon fish": [false,false],
"neon tetra": [false,false],
"nibble fish": [false,false],
"oarfish": [false,false],
"ocean sunfish": [false,false],
"olive flounder": [false,false],
"pale chub": [false,false],
"pike": [false,false],
"piranha": [false,false],
"pond smelt": [false,false],
"pop-eyed goldfish": [false,false],
"puffer fish": [false,false],
"rainbowfish": [false,false],
"ranchu goldfish": [false,false],
"ray": [false,false],
"red snapper": [false,false],
"ribbon eel": [false,false],
"saddled bichir": [false,false],
"salmon": [false,false],
"saw shark": [false,false],
"sea bass": [false,false],
"sea butterfly": [false,false],
"seahorse": [false,false],
"snapping turtle": [false,false],
"soft-shelled turtle": [false,false],
"squid": [false,false],
"stringfish": [false,false],
"sturgeon": [false,false],
"suckerfish": [false,false],
"surgeonfish": [false,false],
"sweetfish": [false,false],
"tadpole": [false,false],
"tilapia": [false,false],
"tuna": [false,false],
"whale shark": [false,false],
"yellow perch": [false,false],
"zebra turkeyfish": [false,false]}

export default critters;
